import React from "react";
import Futer from "./Landing/Futer";
import Navmenu from "./Landing/Navmenu";
import Checkout from './CheckoutForm/Checkout';
import Home2 from "./Landing/Home2";

const Pay = () => {


    return (

        <React.Fragment>
            <Navmenu></Navmenu>
            <Home2></Home2>
            <Checkout></Checkout>
            <Futer></Futer>
        </React.Fragment>
    )





}


export default Pay;